<template>
    <div class="content-wrapper">
        <!-- Content Header (Page header) -->
        <div class="content-header">
            <div class="container-fluid">
                <div class="row mb-2">
                    <div class="col-sm-6">
                        <h1 class="m-0 text-dark">All District</h1>
                    </div><!-- /.col -->
                    <div class="col-sm-6">
                        <button class="btn btn-info float-right" @click="showCreateModal"><i class="fas fa-plus-circle"></i> Create</button>
                    </div><!-- /.col -->
                </div><!-- /.row -->
            </div><!-- /.container-fluid -->
        </div>
        <!-- /.content-header -->
        <!-- Main content -->
        <section class="content">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-12">
                        <div class="card">
                            <div class="card-header">
                                <div class="row">
                                    <div class="col-md-2">
                                        <div class="form-group">
                                            <label for="division_id">Division</label>
                                            <v-select name="division_id"
                                                v-model="search.division_id"
                                                label="text"
                                                :reduce="item => item.id"
                                                :options= commonObj.divisionList
                                                :placeholder="$t('globalTrans.select')"
                                            />
                                        </div>
                                    </div>
                                    <div class="col-xl-2 col-lg-2 col-md-2 col-sm-6 col-xs-12">
                                        <div class="form-group">
                                            <label for="name">Name</label>
                                            <input type="text" id="name" v-model="search.name" placeholder="Enter Name" class="form-control" />
                                        </div>
                                    </div>
                                    <div class="col-xl-2 col-lg-2 col-md-2 col-sm-6 col-xs-12">
                                        <div class="form-group">
                                            <button class="btn btn-info btn-sm" type="submit" @click.prevent="searchData" style="margin-top:28px;"><i class="fa fa-search"></i></button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="card-body">
                                <b-overlay :show="loader">
                                    <div class="table-responsive">
                                        <table class="table table-bordered table-striped table-sm">
                                            <thead>
                                                <tr>
                                                    <th>ID</th>
                                                    <th>Division</th>
                                                    <th>Name</th>
                                                    <th style="text-align:center;">Action</th>
                                                </tr>
                                            </thead>
                                            <tfoot>
                                                <tr>
                                                    <th>ID</th>
                                                    <th>Division</th>
                                                    <th>Name</th>
                                                    <th style="text-align:center;">Action</th>
                                                </tr>
                                            </tfoot>
                                            <tbody>
                                                <tr v-for="(item, key) in listData" :key="key">
                                                    <td>{{ item.id }}</td>
                                                    <td>{{ item.division ? item.division.name : '' }}</td>
                                                    <td>{{ item.name }}</td>
                                                    <td style="text-align:center;">
                                                        <button class="btn btn-warning btn-sm mr-1" @click="editDistrict(key, item)"><i class="fas fa-pencil-alt"></i></button>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <div class="mt-3">
                                        <b-pagination
                                            v-model="pagination.currentPage"
                                            :per-page="pagination.perPage"
                                            :total-rows="pagination.totalRows"
                                            @input="searchData"
                                        />
                                    </div>
                                </b-overlay>
                            </div>
                        </div>
                    </div>
                </div>
            </div><!-- /.container-fluid -->
        </section>
        <div v-if="createModal" class="modal fade show" style="display:block" tabindex="-1" id="addHubModal" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <b-overlay :show="loader">
                        <div class="modal-header bg-info text-white">
                            <h5 class="modal-title text-center w-100">Create New Thana</h5>
                            <button type="button" class="close" aria-label="Close" @click="cancelModal">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body">
                            <ValidationObserver ref="form" v-slot="{ handleSubmit }">
                                <form @submit.prevent="handleSubmit(createThana)">
                                    <div class="row">
                                        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                            <ValidationProvider name="Division" vid="division_id" v-slot="{errors}" rules="required|min_value:1">
                                                <div class="form-group">
                                                    <label for="division_id">Division <span class="text-danger" title="Required">*</span></label>
                                                    <v-select name="division_id"
                                                        v-model="form.division_id"
                                                        label="text"
                                                        :reduce="item => item.id"
                                                        :options= commonObj.divisionList
                                                        :placeholder="$t('globalTrans.select')"
                                                    />
                                                    <span class="text-danger">{{ errors[0] }}</span>
                                                </div>
                                            </ValidationProvider>
                                        </div>
                                        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                            <ValidationProvider name="Name" vid="name" v-slot="{errors}" rules="required">
                                                <div class="form-group">
                                                    <label for="name">Name <span class="text-danger" title="Required">*</span></label>
                                                    <input type="text" id="name" v-model="form.name" class="form-control" placeholder="Name">
                                                    <span class="text-danger">{{ errors[0] }}</span>
                                                </div>
                                            </ValidationProvider>
                                        </div>                        
                                    </div>
                                </form>
                            </ValidationObserver>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-info" @click.prevent="createThana">Save</button>
                            <button type="button" class="btn btn-danger" @click="cancelModal">Cancel</button>
                        </div>
                    </b-overlay>
                </div>
            </div>
        </div>
        <div v-if="editModal" class="modal fade show" style="display:block" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <b-overlay :show="loader">
                        <div class="modal-header bg-info text-white">
                            <h5 class="modal-title text-center w-100">Edit Thana</h5>
                            <button type="button" class="close" aria-label="Close" @click="cancelModal">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body">     
                            <ValidationObserver ref="form" v-slot="{ handleSubmit }">
                                <form @submit.prevent="handleSubmit(updateThana)">                   
                                    <div class="row">
                                        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                            <ValidationProvider name="Division" vid="division_id" v-slot="{errors}" rules="required|min_value:1">
                                                <div class="form-group">
                                                    <label for="division_id">Division <span class="text-danger" title="Required">*</span></label>
                                                    <v-select name="division_id"
                                                        v-model="form_edit.division_id"
                                                        label="text"
                                                        :reduce="item => item.id"
                                                        :options= commonObj.divisionList
                                                        :placeholder="$t('globalTrans.select')"
                                                    />
                                                    <span class="text-danger">{{ errors[0] }}</span>
                                                </div>
                                            </ValidationProvider>
                                        </div>
                                        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                            <ValidationProvider name="Name" vid="name" v-slot="{errors}" rules="required">
                                                <div class="form-group">
                                                    <label for="name">Name <span class="text-danger" title="Required">*</span></label>
                                                    <input type="text" id="name" v-model="form_edit.name" class="form-control" placeholder="Name">
                                                    <span class="text-danger">{{ errors[0] }}</span>
                                                </div>
                                            </ValidationProvider>
                                        </div>                         
                                    </div>
                                </form>
                            </ValidationObserver>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-info" @click.prevent="updateThana">Update</button>
                            <button type="button" class="btn btn-danger" @click="cancelModal">Cancel</button>
                        </div>
                    </b-overlay>
                </div>
            </div>
        </div>
        <div v-if="deleteModal" class="modal fade show" style="display:block" tabindex="-1" id="deleteThanaModal" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-default" role="document">
                <div class="modal-content">
                    <b-overlay :show="loader">
                        <div class="modal-header bg-info text-white">
                            <h5 class="modal-title text-center w-100">Delete Confirmation</h5>
                        </div>
                        <div class="modal-body">
                            <input type="hidden" name="del_id" />
                            <h3 class="text-center">Are you sure to delete ?</h3>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-info" @click="destroyThana">Delete</button>
                            <button type="button" class="btn btn-danger" @click="cancelModal">Cancel</button>
                        </div>
                    </b-overlay>
                </div>
            </div>
        </div>
        <!-- /.content -->
    </div>
</template>
<script>
import config from '@/config'
export default {
    name:'Thana',
    data(){
        return {
            loader: false,
            search: {
                division_id : '',
                name: ''
            },
            form:{
                division_id: '',
                name: ''
            },
            form_edit: {},
            errors:[],
            createModal:false,
            editModal:false,
            deleteModal:false,
            item_id:'',
            key:'',
            pagination: {
                currentPage: 1,
                totalRows: 0,
                perPage: this.$store.state.commonObj.perPage,
                slOffset: 1
            }
        }
    },
    created(){
        this.loadData();
    },
    watch: {
        stateReload : function (newVal, oldVal) {
            if (newVal != oldVal) {
                this.loadData()
            }
        },
        loadingState : function (newVal, oldVal) {
            if (newVal != oldVal) {
                this.loadData()
            }
        },
        'search.name': function (newVal, oldVal) {
            if (newVal != oldVal) {
                this.loadData()
            }
        },
        'search.division_id': function (newVal, oldVal) {
            if (newVal != oldVal) {
                this.districtList = this.getDistrictList(newVal)
            }
        },
        'form.division_id': function (newVal, oldVal) {
            if (newVal != oldVal) {
                this.districtList = this.getDistrictList(newVal)
            }
        },
        'form_edit.division_id': function (newVal, oldVal) {
            if (newVal != oldVal) {
                this.districtList = this.getDistrictList(newVal)
            }
        }
    },
    computed : {
        stateReload () {
            return this.$store.state.stateReload
        },
        listData () {
            return this.$store.state.list
        },
        commonObj () {
            return this.$store.state.commonObj
        },
    },
    methods:{
        searchData () {
            this.loadData()
        },
        async loadData(){     
            this.loader = true 
            const params = Object.assign({}, this.search, { page: this.pagination.currentPage, per_page: this.pagination.perPage })          
            const response = await config.getData('/district', params)
            this.loader = false
            if (response.status == 200){
                this.$store.dispatch('setList', response.data.data)
                this.paginationData(response.data)
            } else {
                this.$store.dispatch('setList', [])
            } 
        },
        paginationData (data) {
            this.pagination.currentPage = data.current_page
            this.pagination.totalRows = data.total
            this.pagination.perPage = data.per_page
            this.pagination.slOffset = this.pagination.perPage * this.pagination.currentPage - this.pagination.perPage + 1
        },
        showCreateModal(){
            this.createModal = true;
        },
        async createThana(){
            this.loader = true
            this.$store.dispatch('stateReload', true)
            const response = await config.postData('/district/store', this.form)
            this.loader = false
            if (response.status == 201) {
                this.form.division_id = this.form.name = ''
                this.$store.dispatch('commonObjLoad', true)
                this.createModal = false
                this.$toast.success({
                    title: 'Success',
                    message: 'District created successfully',
                    color: '#218838'
                }) 
            } else {
                this.$refs.form.setErrors(response.errors)
                this.$toast.error({
                    title: 'Error',
                    message: 'Sorry, something went wrong',
                    color: '#dc3545'
                })  
            }
            this.$store.dispatch('stateReload', false)
        },
        cancelModal(){
            this.createModal = false;
            this.editModal = false;
            this.deleteModal = false;
        },
        editDistrict(key, item){
            this.key = key
            this.editModal = true
            Object.assign(this.form_edit,{
                id: item.id,
                division_id: item.division_id,
                name: item.name
            })
        },
        async updateThana(){
            this.loader = true
            this.$store.dispatch('stateReload', true)
            const formData = {
                id: this.form_edit.id,
                division_id: this.form_edit.division_id,
                name: this.form_edit.name
            }
            const response = await config.postData(`/district/update`, formData)
            this.loader = false
            if (response.status == 201) {
                this.$store.dispatch('commonObjLoad', true)
                this.editModal = false;            
                this.$toast.success({
                    title: 'Success',
                    message: 'Thana updated successfully',
                    color: '#218838'
                })  
            } else {
                this.$refs.form.setErrors(response.errors)
                this.$toast.error({
                    title: 'Error',
                    message: 'Sorry, something went wrong',
                    color: '#dc3545'
                })  
            }
            this.$store.dispatch('stateReload', false)
        },
        deleteThana(key, item_id){
            this.item_id = item_id;
            this.key = key;
            this.deleteModal = true;
        },
        async destroyThana(){
            this.loader = true
            this.$store.dispatch('stateReload', true)
            await config.deleteData('/district/destroy/'+ this.item_id)
            this.loader = false           
            this.deleteModal = false
            this.$toast.success({
                title: 'Success',
                message: 'Bank deleted successfully',
                color: '#218838'
            }) 
            this.$store.dispatch('stateReload', false)
        },
        getDistrictList (divisionId) {
            return this.commonObj.districtList.filter(dist => dist.division_id == divisionId)
        }
    }
}
</script>